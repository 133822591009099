import React, { useState } from "react";
import { Box, Stack, styled, SxProps, Tooltip, tooltipClasses, TooltipProps, Typography, Modal, Paper } from "@mui/material";
import { FaSignInAlt, FaSignOutAlt, FaStar } from "react-icons/fa";
import { firebaseDateToText } from "../../../common/utils";
import { generateFirebaseImageUrl } from "../../../common/utils/helpers";

interface MapMarkerProps {
  lat: number;
  lng: number;
  user: any;
  type: 'clockIn' | 'clockOut' | 'history' | 'taskEnd';
  hovered: boolean;
  onClickDriver: () => void;
  time: any;
  onHover?: () => void;
  onLeave?: () => void;
  color?: string;
}

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#444',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
  },
}));

const MapMarker = ({ lat, lng, user, type, hovered, onClickDriver, time, onHover, onLeave, color }: MapMarkerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userDetails = [
    [type === 'clockOut' ? 'Clock Out' : type === 'clockIn' ? 'Clock In' : '', firebaseDateToText(time)]
  ];

  const iconAttr = {
    size: type === 'history' ? 15 : 26
  };
  const markerIcon = (() => {
    switch (type) {
      case 'clockOut':
        return <FaSignOutAlt size={iconAttr.size} color="#001529" />;
      case 'history':
        return (
          <div
            style={{
              width: iconAttr.size,
              height: iconAttr.size,
              borderRadius: '50%',
              backgroundColor: '#4CAF50',
            }}
          />
        );
      case 'taskEnd':
        return <FaStar size={iconAttr.size} color={color || "#FFD700"} />;
      case 'clockIn':
      default:
        return <FaSignInAlt size={iconAttr.size} color="#eec022" />;
    }
  })();

  const textSx: SxProps = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' };
  const tooltipContent = (
    <Box>
      {userDetails.map((deets, index) => (
        <Stack key={index} direction="row" alignItems="center" gap={1}>
          {deets[0] && <Typography variant="body2" sx={textSx}>
            {deets[0]}:
          </Typography>}
          <Typography variant="body2" sx={{ ...textSx, fontWeight: 800 }}>
            {deets[1]}
          </Typography>
        </Stack>
      ))}
    </Box>
  );

  const handleMarkerClick = () => {
    if (type === 'clockIn' || type === 'clockOut') {
      setIsModalOpen(true);
    }
    onClickDriver();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getImageUrl = () => {
    if (type === 'clockIn' && user.clockIn && user.clockIn.image) {
      return generateFirebaseImageUrl('timesheet', user.clockIn.image);
    } else if (type === 'clockOut' && user.clockOut && user.clockOut.image) {
      return generateFirebaseImageUrl('timesheet', user.clockOut.image);
    }

    return "";
  };

  return (
    <>
      <CustomToolTip open={hovered} title={tooltipContent} placement="bottom" arrow>
        <Box
          sx={{ cursor: 'pointer', width: iconAttr.size }}
          onClick={handleMarkerClick}
          onMouseEnter={onHover}  // Add this line
          onMouseLeave={onLeave}  // Add this line
        >
          {markerIcon}
        </Box>
      </CustomToolTip>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="clock-in-out-image"
      >
        <Paper sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '500px',
          maxHeight: '90vh',
          overflow: 'auto',
          p: 3,
          outline: 'none',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {type === 'clockIn' ? 'Clock In Details' : 'Clock Out Details'}
          </Typography>
          <Box mb={2}>
            {userDetails.map((detail, index) => (
              <Typography key={index} variant="body1">
                <strong>{detail[0]}:</strong> {detail[1]}
              </Typography>
            ))}
          </Box>
          <img src={getImageUrl()} alt={`${type} image`} style={{ width: '100%', height: 'auto' }} />
        </Paper>
      </Modal>
    </>
  )
}

export default MapMarker;